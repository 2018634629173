import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import cogoToast from 'cogo-toast';

import { AsyncDispatch } from '@redux/types';
import { getSubscriptions } from '@redux/modules/subscriptions/selectors';
import BackButton from '@components/BackButton';
import {
    createUserSubscriptions,
    fetchSubscriptions,
} from '@redux/modules/subscriptions/actions';

import Form, { AddUserSubscriptionFormData } from './Form';
import styles from './styles.module.css';

export const AddSubscription: React.FC = () => {
    const dispatch = useDispatch<AsyncDispatch>();
    const history = useHistory();
    const subscriptions = useSelector(getSubscriptions);
    const onSubmit = useCallback(
        (values: AddUserSubscriptionFormData) => {
            dispatch(createUserSubscriptions(values))
                .then((legalEntity) => {
                    if (legalEntity) {
                        cogoToast.success('Подписка пользователя создана', {
                            position: 'top-right',
                            hideAfter: 5,
                        });
                        history.push('/dashboard/subscriptions');
                    }
                })
                .catch((e) => {
                    cogoToast.error(
                        e.response.data.message
                            ? e.response.data.message
                            : 'Ошибка при создании подписки пользователя',
                        {
                            position: 'top-right',
                            hideAfter: 4,
                        }
                    );
                });
        },
        [dispatch, history]
    );

    useEffect(() => {
        dispatch(fetchSubscriptions());
    }, [dispatch]);

    const subscriptionsOptions = subscriptions.reduce(
        (acc: { title: string; value: string }[], subscription) => {
            acc.push({
                title: subscription.title,
                value: subscription._id,
            });
            return acc;
        },
        []
    );

    return (
        <div className={styles.container}>
            <h1>Добавить подписку пользователя</h1>

            <BackButton title="К списку подписок" className={styles.backBtn} />

            <Form
                onSubmit={onSubmit}
                subscriptionsOptions={subscriptionsOptions}
            />
        </div>
    );
};

export default AddSubscription;
