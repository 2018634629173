import React, {
    useEffect,
    useRef,
    useState,
    useCallback,
    SyntheticEvent,
} from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import cogoToast from 'cogo-toast';
import { useDebounce } from 'hooks/debounce';

import { getLegalentitys } from '@redux/modules/legal-entity/selectors';
import {
    fetchScrollLegalEntitys,
    searchLegalEntitys,
    fetchLegalEntitys,
} from '@redux/modules/legal-entity/actions';
import Box from '@components/Box';
import BoxButton from '@components/BoxButton';
import { getGymsList } from '@redux/modules/gyms/selectors';
import { fetchGymsList } from '@redux/modules/gyms/actions';
import Loader from '@components/Loader';
import { AsyncDispatch } from '@redux/types';

import LegalEntityListTable from './LegalEntityListTable';
import styles from './styles.module.css';

export const LegalEntityList: React.FC = () => {
    const dispatch = useDispatch<AsyncDispatch>();
    const history = useHistory();
    const gyms = useSelector(getGymsList);
    const legalEntitys = useSelector(getLegalentitys);
    const [loading, setLoading] = useState(false);
    const [fetching, setFetching] = useState(false);
    const [legalEntitysIsOver, setLegalEntitysIsOver] = useState(false);
    const legalEntitySearchingField = useRef<any>();
    const [searchValuse, setSearchValuse] = useState<string>('');

    const scrollHandler = (event: Event) => {
        if (
            (event.target as Document).documentElement.scrollHeight -
                ((event.target as Document).documentElement.scrollTop +
                    window.innerHeight) <
                500 &&
            (event.target as Document).documentElement.scrollTop > 1000
        ) {
            setFetching(true);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', scrollHandler);

        return () => {
            window.removeEventListener('scroll', scrollHandler);
        };
    }, []);

    useEffect(() => {
        dispatch(fetchGymsList());
        dispatch(fetchLegalEntitys(0))
            .then((legalEntitys) => {
                if (legalEntitys.length < 99) {
                    setLoading(false);
                    setLegalEntitysIsOver(true);
                } else {
                    setLoading(false);
                    setLegalEntitysIsOver(false);
                }
            })
            .catch(() => {
                cogoToast.error('Ошибка при загрузке юр лиц', {
                    position: 'top-right',
                    hideAfter: 4,
                });
            })
            .finally(() => setFetching(false));
    }, [dispatch]);

    useEffect(() => {
        if (fetching && !legalEntitysIsOver) {
            setLoading(true);
            dispatch(fetchScrollLegalEntitys(legalEntitys.length))
                .then((legalEntitys) => {
                    if (legalEntitys.length < 99) {
                        setLoading(false);
                        setLegalEntitysIsOver(true);
                    } else {
                        setLoading(false);
                    }
                })
                .catch(() => {
                    cogoToast.error('Ошибка при загрузке юр лиц', {
                        position: 'top-right',
                        hideAfter: 4,
                    });
                })
                .finally(() => setFetching(false));
        }
    }, [dispatch, fetching]);

    const legalEntitysWithGymTitle = legalEntitys.map((legalEntity) => {
        return {
            ...legalEntity,
            gymsTitle: legalEntity.gyms.map((gymId) => {
                const gym = gyms.find((gym) => gym._id === gymId);
                return gym ? gym.title : gymId;
            }),
        };
    });

    const debounced = useDebounce(searchValuse, 500);

    useEffect(() => {
        if (!debounced || debounced.length < 3) {
            return;
        }
        dispatch(searchLegalEntitys(debounced))
            .then((legalEntitys) => {
                setLegalEntitysIsOver(true);
                if (!legalEntitys.length) {
                    cogoToast.error('Юр лицо не найдено', {
                        position: 'top-right',
                        hideAfter: 4,
                    });
                }
            })
            .catch(() => {
                cogoToast.error('Ошибка при поиске юр лица', {
                    position: 'top-right',
                    hideAfter: 4,
                });
            })
            .finally(() => setLoading(false));
    }, [debounced]);

    const handleSearchSubmit = (event: SyntheticEvent) => {
        event.preventDefault();
        const value = legalEntitySearchingField.current?.value;
        if (!value) {
            dispatch(fetchLegalEntitys(0));
            setLegalEntitysIsOver(false);
        }
        setSearchValuse(value);
    };

    const onAddManagerBtnClick = useCallback(
        () => history.push('/dashboard/add-legalentity'),
        [history]
    );

    return (
        <div>
            <h1>Юридические лица</h1>

            <h3>Действия</h3>
            <div className={styles.actions}>
                <BoxButton
                    icon="gym"
                    title="Добавить юр лицо"
                    onClick={onAddManagerBtnClick}
                    className={styles.actionBtn}
                />

                <BoxButton
                    icon="spreadsheet"
                    title="Выгрузить данные"
                    onClick={() => {
                        // do nothing
                    }}
                    className={styles.actionBtn}
                />
            </div>
            <input
                className={styles.input}
                type="search"
                placeholder="Поиск по названию зала / юр лица"
                ref={legalEntitySearchingField}
                onChange={handleSearchSubmit}
            />
            {legalEntitys.length ? (
                <Box className={styles.container}>
                    <div className={styles.tableWrapper}>
                        <LegalEntityListTable
                            legalEntitys={legalEntitysWithGymTitle}
                            loading={loading}
                        />
                    </div>
                </Box>
            ) : legalEntitys.length === 0 ? null : (
                <Loader />
            )}
        </div>
    );
};

export default LegalEntityList;
