export enum GenderType {
    FEMALE = 'FEMALE',
    MALE = 'MALE',
    ALL = 'ALL',
}
export type WeekDaySchedule = {
    weekDay: number;
    dateStart: Date;
    dateFinish: Date;
};

export enum WeekDay {
    MONDAY = 1,
    TUESDAY = 2,
    WEDNESDAY = 3,
    THURSDAY = 4,
    FRIDAY = 5,
    SATURDAY = 6,
    SUNDAY = 0,
    DAILY = -1,
    WEEKDAY = -2,
    WEEKEND = -3,
}

export enum TableTentType {
    TENT_PER_MIN_1 = 'perMinPurple',
    TENT_PER_MIN_2 = 'perMinWhite',
    TENT_SPIRIT = 'spiritWhite',
    TENT_WORKOUTS_1 = 'classWhite',
    TENT_MASSAGE_1 = 'massageWhite',
    TENT_DEFAULT_OLD = 'old',
}

export const WeekDaysOptions = [
    { title: 'Ежедневно', value: WeekDay.DAILY },
    { title: 'Будни', value: WeekDay.WEEKDAY },
    { title: 'Выходные', value: WeekDay.WEEKEND },
    { title: 'Понедельник', value: WeekDay.MONDAY },
    { title: 'Вторник', value: WeekDay.TUESDAY },
    { title: 'Среда', value: WeekDay.WEDNESDAY },
    { title: 'Четверг', value: WeekDay.THURSDAY },
    { title: 'Пятница', value: WeekDay.FRIDAY },
    { title: 'Суббота', value: WeekDay.SATURDAY },
    { title: 'Воскресенье', value: WeekDay.SUNDAY },
];
export type SpecialPrice = {
    weekDay: WeekDay;
    timeStart: Date;
    timeFinish: Date;
    price: number;
};

export type SubwayRoute = {
    station: string;
    duration: number | string;
};

export type CancelEventType = {
    cancelReason: string;
    cancelDate: Date;
};

export interface GroupWorkout {
    _id: string;
    dateStart: Date;
    dateFinish: Date;
    gymId: string;
    price: number;
    title: string;
    limit: number;
    cancelled: boolean;
    cancelReason: string;
    isRecurring: boolean;
    withPrepayment: boolean;
    prepayment: number;
    weekDaySchedule: Array<WeekDaySchedule>;
    cancelledEvents: Array<CancelEventType>;
    genderType: GenderType;
    yclientsWorkoutId: number;
    tags: string[];
}

export interface ITimeSlot {
    _id?: string;
    timeStart: Date | null;
    timeFinish: Date | null;
}

export interface GroupWorkoutNewCreate {
    title: string;
    tags: string[];
    gymId: string;
    limit: number;
    price: number;
    genderType: GenderType;
    description: string;
    dateStart?: Date | null;
    dateEnd?: Date | null;
    mon: Array<ITimeSlot>;
    tue: Array<ITimeSlot>;
    wed: Array<ITimeSlot>;
    thu: Array<ITimeSlot>;
    fri: Array<ITimeSlot>;
    sat: Array<ITimeSlot>;
    sun: Array<ITimeSlot>;
}

export interface GroupWorkoutNewData {
    _id: string;
    title: string;
    tags: string[];
    gymId: string;
    limit: number;
    price: number;
    genderType: GenderType;
    description: string;
    groupworkoutschedule: {
        reservations?: {
            _id: string;
            date: Date;
            groupWorkoutReservationsNew: string;
            timeSlot: string;
        }[];
        dateStart?: Date;
        dateEnd?: Date;
        mon: Array<ITimeSlot>;
        tue: Array<ITimeSlot>;
        wed: Array<ITimeSlot>;
        thu: Array<ITimeSlot>;
        fri: Array<ITimeSlot>;
        sat: Array<ITimeSlot>;
        sun: Array<ITimeSlot>;
    };
}

export interface GroupWorkoutNew {
    _id: string;
    title: string;
    active: boolean;
    tags: string[];
    gymId: string;
    limit: number;
    freeReserve: number;
    price: number;
    genderType: GenderType;
    yclientsWorkoutId: number;
    recordsCount: number;
    description: string;
    schedule: string[];
    groupworkoutschedule: IGroupWorkoutNewSchedules;
    date: Date;
    timeStart: Date;
    timeFinish: Date;
    timeSlotId: string;
    createdAt: Date;
}

export interface IGroupWorkoutNewSchedules {
    _id: string;
    dateStart: Date;
    dateEnd: Date;
    mon: Array<ITimeSlot>;
    tue: Array<ITimeSlot>;
    wed: Array<ITimeSlot>;
    thu: Array<ITimeSlot>;
    fri: Array<ITimeSlot>;
    sat: Array<ITimeSlot>;
    sun: Array<ITimeSlot>;
    reservations: Array<ReservationsType>;
    cancelledEvents: Array<CancelledEventsType>;
}

export type CancelledEventsType = {
    cancelDate: string;
    reason: string;
    timeSlot: string;
};

export type ReservationsType = {
    _id: string;
    date: Date;
    groupWorkoutReservationsNew: string;
    timeSlot: string;
};

export interface SingleWorkoutPriceInfo {
    weekDayStartTime: Date;
    weekDayFinishTime: Date;
    weekDayPrice: number;
    weekDayOffPeakStartTime: Date;
    weekDayOffPeakFinishTime: Date;
    weekDayOffPeakPrice: number;
    weekendStartTime: Date;
    weekendFinishTime: Date;
    weekendPrice: number;
    weekendOffPeakStartTime: Date;
    weekendOffPeakFinishTime: Date;
    weekendOffPeakPrice: number;
}

export interface WorkingHours {
    weekDay: WeekDay;
    timeStart: Date;
    timeFinish: Date;
    aroundTheClock: boolean;
}

export interface City {
    _id: string;
    city: string;
    regionName: string;
}

export interface Gym {
    _id: string;
    title: string;
    address: string;
    descriptionLong: string;
    pricePerMinRub: number;
    authorized: boolean;
    deleted: boolean;
    location: {
        type: string;
        coordinates: number[];
    };
    subwayRoutes: SubwayRoute[];
    social: {
        instagram: string;
        vk: string;
        facebook: string;
        telegram: string;
    };
    groupWorkouts: string[];
    availableHours: string[][];
    workingHours: WorkingHours[];
    hasSingleWorkouts: boolean;
    hasPerMinWorkouts: boolean;
    hasGroupWorkouts: boolean;
    hasMemberships: boolean;
    memberships: string[];
    hasMinWorkoutDuration: boolean;
    minWorkoutDuration: number;
    minGroupWorkoutPrice: number;
    singleWorkoutPriceInfo: SingleWorkoutPriceInfo;
    tags: {
        dayAndNight?: boolean;
        drinkingWater?: boolean;
        towels?: boolean;
        pilates?: boolean;
        crossfit?: boolean;
        yoga?: boolean;
        stretching?: boolean;
        combatZone?: boolean;
        finnishSauna?: boolean;
        turkishHammam?: boolean;
        fitnessBar?: boolean;
        swimmingPool?: boolean;
        childrenRoom?: boolean;
        parking?: boolean;
        cardioEquipment?: boolean;
        shower?: boolean;
    };
    website: string;
    phone: string;
    withSingleWorkoutsOption: boolean;
    hasWeekDayPrimeTime: boolean;
    hasWeekendPrimeTime: boolean;
    legalEntity: string;
    legalEntityId: string;
    managersId: string[];
    hasPassport: boolean;
    isHidden: boolean;
    hasSpecialPrice: boolean;
    specialLowPrice: {
        actionStart: Date;
        actionEnd: Date;
        price: number;
    };
    city: string;
    cityId: string;
    yclientsId: string;
    discountFromYclientsPrice: number;
    prepaymentPercentFromYclientsPrice: number;
    services: string[];
    advantages: string[];
    docs: string[];
    seasonal: boolean;
    previewSources: {
        images: File[];
        tours: string[];
        videos: string[];
        icon?: File;
    };
    prepayment: number;
    shortAddress: string;
    spirit: boolean;
}
