import React, { useMemo, useState } from 'react';
import classNames from 'classnames';
import {
    Field,
    FieldArray,
    FieldArrayRenderProps,
    Form,
    FormikProvider,
    useFormik,
} from 'formik';

import Button from '@components/Button';
import QuestionMark from '@components/Icons/QuestionMark';
import Delete from '@components/Icons/Delete';

import { FileUpload } from './components/FileUpload';
import { TextareaField } from './components/TextareaField';
import { SelectField } from './components/SelectField';
import { InputField } from './components/InputField';
import styles from './styles.module.css';

export interface UpdateGoGymSubscriptionFormData {
    active: boolean;
    activeForProlongation: boolean;
    isAdmin: boolean;
    title: string;
    titleDescription: string;
    description: string;
    iconUrl: string;
    additionalDescription: string;
    pricePerMonth: number;
    gyms: string[];
    gymsExclusionList: string[];
    minPerMinPrice: number;
    maxPerMinPrice: number;
    workoutType: 'PER_MIN_WORKOUT' | string;
    advantages: {
        title: string;
        description: string;
        imageUrl: string;
    }[];
}

export interface Props {
    subscriptionsOptions: {
        title: string;
        value: string;
    }[];
}

type OptionValue = { label: string; value: string };

interface SubscriptionFormProps {
    subscriptionIconFileRef:
        | React.MutableRefObject<null>
        | React.MutableRefObject<HTMLInputElement>;
    subscriptionAdvantagesIconFileRef: any;
    gymOptions: OptionValue[];
    initialValues: UpdateGoGymSubscriptionFormData;
    onCancel: () => void;
    onSubmit: (values: UpdateGoGymSubscriptionFormData) => void;
    onUploadFile: (event: any) => void;
    workoutTypesOptions: OptionValue[];
}

function getEqualValuesArray(arr1: string[], arr2: string[]) {
    return arr1.filter((value) => arr2.includes(value));
}

function getGymTitlesFormattedFromOptions(
    gymsIdsArr: string[],
    gymOptions: OptionValue[]
) {
    const gymTitles = gymsIdsArr.reduce<string[]>((acc, gym) => {
        const option = gymOptions.find((gymOption) => gymOption.value === gym);

        if (option) {
            acc.push(option.label);
        }

        return acc;
    }, []);
    return gymTitles.join(', ');
}

const UpdateSubscriptionGoGymForm: React.FC<SubscriptionFormProps> = ({
    initialValues,
    subscriptionIconFileRef,
    subscriptionAdvantagesIconFileRef,
    onUploadFile,
    onSubmit,
    onCancel,
    workoutTypesOptions,
    gymOptions,
}) => {
    const [tooltips, setTooltips] = useState({
        renewal: false,
    });

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: initialValues,
        onSubmit: (values) => {
            onSubmit(values);
        },
        validate: (values) => {
            const errors: Partial<{
                title: string;
                iconUrl: string;
                pricePerMonth: string;
                minPerMinPrice: string;
                maxPerMinPrice: string;
                gyms: string;
                gymsExclusionList: string;
                workoutType: string;
                advantages: string | any[];
            }> = {};

            if (!values.title) {
                errors.title = 'Необходимо указать название';
            }

            if (
                !values.iconUrl &&
                !subscriptionIconFileRef.current?.files?.length
            ) {
                errors.iconUrl = 'Необходимо загрузить иконку';
            }

            if (!values.pricePerMonth) {
                errors.pricePerMonth = 'Необходимо указать стоимость подписки';
            }

            if (
                isNaN(Number(values.pricePerMonth)) ||
                !/^[\d]+$/.test(values.pricePerMonth.toString())
            ) {
                errors.pricePerMonth = 'Должно быть числом';
            }

            const gymsEqualArray = getEqualValuesArray(
                values.gyms,
                values.gymsExclusionList
            );

            if (gymsEqualArray.length) {
                const gymTitlesFormatted = getGymTitlesFormattedFromOptions(
                    gymsEqualArray,
                    gymOptions
                );
                errors.gyms = `Залы: ${gymTitlesFormatted} также повторяются в поле "Залы не входящие в подписку"`;
            }

            const gymsExclusionListEqualArray = getEqualValuesArray(
                values.gymsExclusionList,
                values.gyms
            );

            if (gymsExclusionListEqualArray.length) {
                const gymTitlesFormatted = getGymTitlesFormattedFromOptions(
                    gymsEqualArray,
                    gymOptions
                );
                errors.gymsExclusionList = `Залы: ${gymTitlesFormatted} также повторяются в поле "Залы входящие в подписку"`;
            }

            if (
                (values.minPerMinPrice &&
                    isNaN(Number(values.minPerMinPrice))) ||
                !/^[\d]+$/.test(values.minPerMinPrice.toString())
            ) {
                errors.minPerMinPrice = 'Должно быть числом';
            }

            if (
                (values.maxPerMinPrice &&
                    isNaN(Number(values.maxPerMinPrice))) ||
                !/^[\d]+$/.test(values.maxPerMinPrice.toString())
            ) {
                errors.maxPerMinPrice = 'Должно быть числом';
            }

            if (!values.workoutType) {
                errors.workoutType = 'Необходимо выбрать тип тренировки';
            }

            if (values.advantages.length) {
                const advantagesArr = values.advantages;
                const advantagesErrorsArr = advantagesArr.map(
                    (advantage, index) => {
                        const obj: Partial<{
                            title: string;
                            description: string;
                            imageUrl: string;
                        }> = {};

                        if (!advantage.title) {
                            obj.title = 'Необходимо указать название';
                        }

                        if (
                            !advantage.imageUrl &&
                            !subscriptionAdvantagesIconFileRef.current?.[
                                `advantages.${index}.imageUrl`
                            ]?.files?.length
                        ) {
                            obj.imageUrl = 'Необходимо загрузить иконку';
                        }

                        if (!obj.title && !obj.imageUrl) {
                            return null;
                        }

                        return obj;
                    }
                );

                const everyElemIsNull = advantagesErrorsArr.every(
                    (element) => element === null
                );
                if (!everyElemIsNull) {
                    errors.advantages = advantagesErrorsArr;
                }
            }

            return errors;
        },
    });

    const handleChangeFieldValue = (name: string) => (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        const { value } = event.target;
        formik.setFieldValue(name, value);
    };

    const handleChangeSelectValue = (name: string) => (
        optionData: OptionValue | OptionValue[]
    ) => {
        if (Array.isArray(optionData)) {
            if (!optionData.length) {
                formik.setFieldValue(name, []);
                return;
            }

            const optionValues = optionData.map((option) => option.value);
            formik.setFieldValue(name, optionValues);
        } else {
            formik.setFieldValue(name, optionData.value);
        }
    };

    const handleChangeFile = (name: string) => (event: any) => {
        formik.setFieldValue(name, '');
        onUploadFile(event);
    };

    const gymDefaultValue = useMemo(() => {
        return gymOptions.filter((option) =>
            initialValues.gyms.includes(option.value)
        );
    }, [gymOptions]);

    const gymExclusionDefaultValue = useMemo(() => {
        return gymOptions.filter((option) =>
            (initialValues.gymsExclusionList ?? []).includes(option.value)
        );
    }, [initialValues.gymsExclusionList, gymOptions]);

    const workoutTypeDefaultValue = useMemo(() => {
        return (
            workoutTypesOptions.find(
                (option) => initialValues.workoutType === option.value
            ) || null
        );
    }, []);

    const renderAdvantages = (renderProps: FieldArrayRenderProps) => {
        return (
            <div className={styles.advantagesContainer}>
                {formik.values.advantages.map((item, idx) => (
                    <div
                        className={styles.advantagesItem}
                        key={String([item.title, idx])}
                    >
                        <div className={styles.advantagesItemHeader}>
                            <div className={styles.advantagesItemHeaderTitle}>
                                Преимущество №{idx + 1}
                            </div>
                            <button
                                type="button"
                                className={styles.advantagesItemHeaderButton}
                                onClick={() => {
                                    renderProps.remove(idx);
                                }}
                            >
                                Удалить
                            </button>
                        </div>
                        <div className={styles.advantagesItemContent}>
                            <InputField
                                name={`advantages.${idx}.title`}
                                label="Заголовок"
                                placeholder="Введите заголовок"
                                maxLength={25}
                                onChange={handleChangeFieldValue(
                                    `advantages.${idx}.title`
                                )}
                                value={formik.values.advantages[idx].title}
                            />
                            <InputField
                                name={`advantages.${idx}.description`}
                                label="Подзаголовок"
                                placeholder="Введите подзаголовок"
                                maxLength={38}
                                onChange={handleChangeFieldValue(
                                    `advantages.${idx}.description`
                                )}
                                value={
                                    formik.values.advantages[idx].description
                                }
                            />
                            <FileUpload
                                fileRef={(ref: any) => {
                                    subscriptionAdvantagesIconFileRef.current[
                                        `advantages.${idx}.imageUrl`
                                    ] = ref;
                                }}
                                name={`advantages.${idx}.imageUrl`}
                                onChange={handleChangeFile(
                                    `advantages.${idx}.imageUrl`
                                )}
                                value={formik.values.advantages[idx].imageUrl}
                                label="Иконка преимущества"
                                accept=".png, .svg"
                                hint="Только форматы .png или .svg, с минимальным размером 104x104px, до 300 кб"
                            />
                        </div>
                    </div>
                ))}

                <button
                    className={styles.addButton}
                    type="button"
                    onClick={() => {
                        renderProps.push({
                            title: '',
                            description: '',
                            imageUrl: '',
                        });
                    }}
                >
                    Добавить преимущество
                </button>
            </div>
        );
    };

    return (
        <FormikProvider value={formik}>
            <Form autoComplete={'off'} className={styles.container}>
                <h3 className={styles.title}>Управление подпиской</h3>
                <div className={styles.row}>
                    <div className={styles.checkboxGroup}>
                        <label className={styles.checkboxLabel}>
                            <Field
                                className={styles.checkbox}
                                type="checkbox"
                                name="active"
                            />
                            Активная
                        </label>
                        <div className={styles.checkboxTooltipContainer}>
                            <label className={styles.checkboxLabel}>
                                <Field
                                    className={styles.checkbox}
                                    type="checkbox"
                                    name="activeForProlongation"
                                />
                                Активна для продления
                            </label>
                            <div
                                className={styles.checkboxTooltip}
                                onClick={() =>
                                    setTooltips((prevState) => ({
                                        ...prevState,
                                        renewal: !prevState.renewal,
                                    }))
                                }
                            >
                                <QuestionMark />
                            </div>
                        </div>
                        {tooltips.renewal && (
                            <div className={styles.tooltip}>
                                <div className={styles.tooltipContent}>
                                    <p>
                                        Если чек-бокс не активен, то подписка не
                                        будет доступна для пролонгации и для
                                        подключения через МП. Если чек-бокс
                                        активен, то подписка будет доступна к
                                        пролонгации после заверешения периода ее
                                        действия
                                    </p>
                                    <div
                                        className={styles.tooltipClose}
                                        onClick={() =>
                                            setTooltips((prevState) => ({
                                                ...prevState,
                                                renewal: false,
                                            }))
                                        }
                                    >
                                        <Delete />
                                    </div>
                                </div>
                            </div>
                        )}
                        <label className={styles.checkboxLabel}>
                            <Field
                                className={styles.checkbox}
                                type="checkbox"
                                name="isAdmin"
                            />
                            Для тестирования
                        </label>
                    </div>
                </div>
                <div className={styles.row}>
                    <div className={classNames(styles.col, styles.colLeft)}>
                        <h3 className={styles.title}>Карточка в списке</h3>
                        <InputField
                            name="title"
                            label="Название подписки"
                            placeholder="Введите название"
                            maxLength={20}
                            onChange={handleChangeFieldValue('title')}
                            value={formik.values.title}
                        />
                        <InputField
                            name="titleDescription"
                            label="Подзаголовок карточки"
                            placeholder="Введите текст"
                            maxLength={30}
                            onChange={handleChangeFieldValue(
                                'titleDescription'
                            )}
                            value={formik.values.titleDescription}
                            hint="Видно только в разделе списка подписок"
                        />
                        <TextareaField
                            label="Описание"
                            name="description"
                            hint="Отображается в детальной информации и в карточке списка"
                            maxLength={130}
                        />
                        <FileUpload
                            fileRef={subscriptionIconFileRef}
                            name="iconUrl"
                            label="Иконка подписки"
                            accept=".png, .svg"
                            hint="Только форматы .png или .svg, с минимальным размером 104x104px, до 300 кб"
                            onChange={handleChangeFile('iconUrl')}
                            value={formik.values.iconUrl}
                        />
                        <InputField
                            name="additionalDescription"
                            label="Доп. описание"
                            placeholder="Введите текст"
                            maxLength={100}
                            onChange={handleChangeFieldValue(
                                'additionalDescription'
                            )}
                            value={formik.values.additionalDescription}
                        />
                        <InputField
                            name="pricePerMonth"
                            label="Стоимость"
                            placeholder="0"
                            onChange={handleChangeFieldValue('pricePerMonth')}
                            value={formik.values.pricePerMonth.toString()}
                        />
                        <SelectField
                            label="Залы входящие в подписку"
                            name="gyms"
                            placeholder="Все залы"
                            isMulti={true}
                            options={gymOptions}
                            defaultValue={gymDefaultValue}
                            key={String(['included', gymDefaultValue.length])}
                            onChange={handleChangeSelectValue('gyms')}
                        />
                        <SelectField
                            label="Залы не входящие в подписку"
                            name="gymsExclusionList"
                            placeholder="Выбрать залы"
                            isMulti={true}
                            options={gymOptions}
                            key={String([
                                'excluded',
                                gymExclusionDefaultValue.length,
                            ])}
                            defaultValue={gymExclusionDefaultValue}
                            onChange={handleChangeSelectValue(
                                'gymsExclusionList'
                            )}
                        />

                        <div className={styles.section}>
                            <h4 className={styles.subtitle}>
                                Подключить залы со стоимостью:{' '}
                            </h4>
                            <div className={styles.row}>
                                <div
                                    className={classNames(
                                        styles.col,
                                        styles.colLeft
                                    )}
                                >
                                    <InputField
                                        name="minPerMinPrice"
                                        label="От (₽/мин. включительно)"
                                        placeholder="0"
                                        onChange={handleChangeFieldValue(
                                            'minPerMinPrice'
                                        )}
                                        value={formik.values.minPerMinPrice.toString()}
                                    />
                                </div>
                                <div
                                    className={classNames(
                                        styles.col,
                                        styles.colRight
                                    )}
                                >
                                    <InputField
                                        name="maxPerMinPrice"
                                        label="До (₽/мин. включительно)"
                                        placeholder="0"
                                        onChange={handleChangeFieldValue(
                                            'maxPerMinPrice'
                                        )}
                                        value={formik.values.maxPerMinPrice.toString()}
                                    />
                                </div>
                            </div>
                            <SelectField
                                label="Тип тренировки"
                                name="workoutType"
                                placeholder="Выбрать тип тренировки"
                                isMulti={false}
                                options={workoutTypesOptions}
                                defaultValue={workoutTypeDefaultValue}
                                onChange={handleChangeSelectValue(
                                    'workoutType'
                                )}
                            />
                        </div>
                    </div>
                    <div className={classNames(styles.col, styles.colRight)}>
                        <h3 className={styles.title}>Преимущества</h3>
                        <FieldArray
                            name={`advantages`}
                            render={renderAdvantages}
                        />
                    </div>
                </div>
                <div className={styles.row}>
                    <div className={styles.actions}>
                        <Button
                            className={classNames(styles.btn, styles.btnSubmit)}
                            type="submit"
                        >
                            Сохранить
                        </Button>
                        <Button
                            className={classNames(styles.btn, styles.btnCancel)}
                            type="button"
                            onClick={onCancel}
                        >
                            Отмена
                        </Button>
                    </div>
                </div>
            </Form>
        </FormikProvider>
    );
};

export default UpdateSubscriptionGoGymForm;
