import axios, { AxiosError } from 'axios';
import qs from 'qs';

import { REPORTS_API_URL, S3_REPORTS_BUCKET_NAME } from '@config';
import { AsyncActionCreator } from '@redux/types';
import { Report } from '@t/report';

import {
    FETCH_REPORTS_REQUEST,
    FETCH_REPORTS_SUCCESS,
    FETCH_REPORTS_ERROR,
    FETCH_REPORT_REQUEST,
    FETCH_REPORT_SUCCESS,
    FETCH_REPORT_ERROR,
    CONFIRM_REPORT_REQUEST,
    CONFIRM_REPORT_SUCCESS,
    CONFIRM_REPORT_ERROR,
    FETCH_BILLPAID_REQUEST,
    FETCH_BILLPAID_SUCCESS,
    FETCH_BILLPAID_ERROR,
    FETCH_HR_REPORTS_REQUEST,
    FETCH_HR_REPORTS_SUCCESS,
    FETCH_HR_REPORTS_ERROR,
    FETCH_HR_REPORT_REQUEST,
    FETCH_HR_REPORT_SUCCESS,
    FETCH_HR_REPORT_ERROR,
    FETCH_REPORT_ALL_BOOKINGS_REQUEST,
    FETCH_REPORT_ALL_BOOKINGS_SUCCESS,
    FETCH_REPORT_ALL_BOOKINGS_ERROR,
    FETCH_REPORT_ALL_USERS_REQUEST,
    FETCH_REPORT_ALL_USERS_ERROR,
    FETCH_REPORT_ALL_GYMS_REQUEST,
    FETCH_REPORT_ALL_GYMS_SUCCESS,
    FETCH_REPORT_ALL_GYMS_ERROR,
    FETCH_REPORT_ALL_LEGALENTITIES_REQUEST,
    FETCH_REPORT_ALL_LEGALENTITIES_SUCCESS,
    FETCH_REPORT_ALL_LEGALENTITIES_ERROR,
    FETCH_REPORT_ALL_CORPORATE_REQUEST,
    FETCH_REPORT_ALL_CORPORATE_SUCCESS,
    FETCH_REPORT_ALL_CORPORATE_ERROR,
    FETCH_REPORT_ALL_ONLINE_WORKOUTS_REQUEST,
    FETCH_REPORT_ALL_ONLINE_WORKOUTS_SUCCESS,
    FETCH_REPORT_ALL_ONLINE_WORKOUTS_ERROR,
    FETCH_REPORT_ACCOUNTING_REQUEST,
    FETCH_REPORT_ACCOUNTING_SUCCESS,
    FETCH_REPORT_ACCOUNTING_ERROR,
    FETCH_REPORT_ALL_MEMBERSHIPS_REQUEST,
    FETCH_REPORT_ALL_MEMBERSHIPS_SUCCESS,
    FETCH_REPORT_ALL_MEMBERSHIPS_ERROR,
    FETCH_REPORT_ALL_USER_SUBSCRIPTIONS_REQUEST,
    FETCH_REPORT_ALL_USER_SUBSCRIPTIONS_SUCCESS,
    FETCH_REPORT_ALL_USER_SUBSCRIPTIONS_ERROR,
    FETCH_REPORTS_BOOKINGS_REQUEST,
    FETCH_REPORTS_BOOKINGS_SUCCESS,
    FETCH_REPORTS_BOOKINGS_ERROR,
    FETCH_REPORTS_MEMBERSHIPS_REQUEST,
    FETCH_REPORTS_MEMBERSHIPS_SUCCESS,
    FETCH_REPORTS_MEMBERSHIPS_ERROR,
    FETCH_REPORT_GYMAFON_REQUEST,
    FETCH_REPORT_GYMAFON_ERROR,
    GENERATE_PARTNER_REPORT_REQUEST,
    GENERATE_PARTNER_REPORT_ERROR,
    GENERATE_PARTNER_REPORT_SUCCESS,
    CONFIRM_PARTNER_REPORT_REQUEST,
    CONFIRM_PARTNER_REPORT_SUCCESS,
    CONFIRM_PARTNER_REPORT_ERROR,
    DELETE_PARTNER_REPORT_REQUEST,
    DELETE_PARTNER_REPORT_SUCCESS,
    DELETE_PARTNER_REPORT_ERROR,
} from './types';
import moment from 'moment';
import cogoToast from 'cogo-toast';

export const fetchReports: AsyncActionCreator<string | null | void> = (
    selectedGymId?: string | null | void
) => async (dispatch, getState) => {
    dispatch({
        type: FETCH_REPORTS_REQUEST,
    });

    const {
        auth: { token },
    } = getState();
    try {
        const { data: reports } = await axios.get(
            `${REPORTS_API_URL}/partners`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params: {
                    selectedGymId: selectedGymId,
                },
                paramsSerializer: (params) => {
                    return qs.stringify(params, { arrayFormat: 'repeat' });
                },
            }
        );

        dispatch({
            type: FETCH_REPORTS_SUCCESS,
            payload: reports,
        });

        return reports;
    } catch (err) {
        if (err instanceof Error) {
            dispatch({
                type: FETCH_REPORTS_ERROR,
            });

            throw err;
        }
    }
};

export const fetchReportsBookings: AsyncActionCreator<string | null | void> = (
    selectedManagerId?: string | null | void
) => async (dispatch, getState) => {
    dispatch({
        type: FETCH_REPORTS_BOOKINGS_REQUEST,
    });

    const {
        auth: { token },
    } = getState();
    try {
        const { data: reports } = await axios.get(
            `${REPORTS_API_URL}/partners/v1`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params: { selectedManagerId, type: 'BOOKING' },
                paramsSerializer: (params) => {
                    return qs.stringify(params, { arrayFormat: 'repeat' });
                },
            }
        );

        dispatch({
            type: FETCH_REPORTS_BOOKINGS_SUCCESS,
            payload: reports,
        });

        return reports;
    } catch (err) {
        if (err instanceof Error) {
            dispatch({
                type: FETCH_REPORTS_BOOKINGS_ERROR,
            });

            throw err;
        }
    }
};

export const fetchReportsMemberships: AsyncActionCreator<
    string | null | void
> = (selectedManagerId?: string | null | void) => async (
    dispatch,
    getState
) => {
    dispatch({
        type: FETCH_REPORTS_MEMBERSHIPS_REQUEST,
    });

    const {
        auth: { token },
    } = getState();
    try {
        const { data: reports } = await axios.get(
            `${REPORTS_API_URL}/partners/v1`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params: { selectedManagerId, type: 'MEMBERSHIP' },
                paramsSerializer: (params) => {
                    return qs.stringify(params, { arrayFormat: 'repeat' });
                },
            }
        );

        dispatch({
            type: FETCH_REPORTS_MEMBERSHIPS_SUCCESS,
            payload: reports,
        });

        return reports;
    } catch (err) {
        if (err instanceof Error) {
            dispatch({
                type: FETCH_REPORTS_MEMBERSHIPS_ERROR,
            });

            throw err;
        }
    }
};

export const fetchReport: AsyncActionCreator<string, Report> = (
    id?: string
) => async (dispatch, getState) => {
    dispatch({
        type: FETCH_REPORT_REQUEST,
    });

    const {
        auth: { token },
    } = getState();

    try {
        const { data: report } = await axios.get(
            `${REPORTS_API_URL}/partners/${id}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        dispatch({
            type: FETCH_REPORT_SUCCESS,
            payload: report,
        });

        return report;
    } catch (err) {
        if (err instanceof Error) {
            dispatch({
                type: FETCH_REPORT_ERROR,
            });

            throw err;
        }
    }
};

export const confirmReport: AsyncActionCreator<string> = (id) => async (
    dispatch,
    getState
) => {
    dispatch({
        type: CONFIRM_REPORT_REQUEST,
    });

    const {
        auth: { token },
    } = getState();

    try {
        const { data: report } = await axios.put(
            `${REPORTS_API_URL}/partners/${id}`,
            {
                status: 'Отчет подтвержден',
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        dispatch({
            type: CONFIRM_REPORT_SUCCESS,
            payload: { report },
        });

        return report;
    } catch (err) {
        if (err instanceof Error) {
            dispatch({
                type: CONFIRM_REPORT_ERROR,
            });

            throw err;
        }
    }
};

export const fetchBillIsPaid: AsyncActionCreator<string> = (id) => async (
    dispatch,
    getState
) => {
    dispatch({
        type: FETCH_BILLPAID_REQUEST,
    });

    const {
        auth: { token },
    } = getState();

    try {
        const { data: report } = await axios.put(
            `${REPORTS_API_URL}/partners/${id}`,
            { status: 'Счет оплачен', paid: true },
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        dispatch({
            type: FETCH_BILLPAID_SUCCESS,
            payload: { report },
        });

        return report;
    } catch (err) {
        if (err instanceof Error) {
            dispatch({
                type: FETCH_BILLPAID_ERROR,
            });

            throw err;
        }
    }
};

export const fetchHRReports: AsyncActionCreator<void, Report[]> = () => async (
    dispatch,
    getState
) => {
    dispatch({
        type: FETCH_HR_REPORTS_REQUEST,
    });

    const {
        auth: { token },
    } = getState();
    try {
        const { data: reports } = await axios.get(`${REPORTS_API_URL}/hr`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        dispatch({
            type: FETCH_HR_REPORTS_SUCCESS,
            payload: reports,
        });

        return reports;
    } catch (err) {
        if (err instanceof Error) {
            dispatch({
                type: FETCH_HR_REPORTS_ERROR,
            });

            throw err;
        }
    }
};

export const fetchHRReport: AsyncActionCreator<string, Report> = (
    id?: string
) => async (dispatch, getState) => {
    dispatch({
        type: FETCH_HR_REPORT_REQUEST,
    });

    const {
        auth: { token },
    } = getState();

    try {
        const { data: report } = await axios.get(
            `${REPORTS_API_URL}/hr/${id}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        dispatch({
            type: FETCH_HR_REPORT_SUCCESS,
            payload: report,
        });

        return report;
    } catch (err) {
        if (err instanceof Error) {
            dispatch({
                type: FETCH_HR_REPORT_ERROR,
            });

            throw err;
        }
    }
};

export const fetchAllBookings: AsyncActionCreator<void, void> = () => async (
    dispatch,
    getState
) => {
    dispatch({
        type: FETCH_REPORT_ALL_BOOKINGS_REQUEST,
    });

    const {
        auth: { token },
    } = getState();
    try {
        const { data: report } = await axios.get(
            `https://api.gogym.fit/${S3_REPORTS_BUCKET_NAME}/admin-bookings/all-time-bookings.xlsx`,
            {
                responseType: 'blob',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        const url = window.URL.createObjectURL(new Blob([report]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
            'download',
            `all-time-bookings-${moment().format('DD-MM-YYYY-HH-mm')}.xlsx`
        );
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(url);
    } catch (err) {
        if (err instanceof Error) {
            dispatch({
                type: FETCH_REPORT_ALL_BOOKINGS_ERROR,
            });

            throw err;
        }
    }
};

export const fetchMonthBookings: AsyncActionCreator<
    { year: string; month: string },
    any
> = (values) => async (dispatch, getState) => {
    dispatch({
        type: FETCH_REPORT_ALL_BOOKINGS_REQUEST,
    });

    const {
        auth: { token },
    } = getState();

    try {
        const { data: report } = await axios.get(
            `${REPORTS_API_URL}/bookings`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params: {
                    type: 'MONTH',
                    year: values.year,
                    month: values.month,
                },
            }
        );

        dispatch({
            type: FETCH_REPORT_ALL_BOOKINGS_SUCCESS,
            payload: report,
        });

        return report;
    } catch (err) {
        if (err instanceof Error) {
            dispatch({
                type: FETCH_REPORT_ALL_BOOKINGS_ERROR,
            });

            throw err;
        }
    }
};

export const fetchYearBookings: AsyncActionCreator<string, void> = (
    year
) => async (dispatch, getState) => {
    dispatch({
        type: FETCH_REPORT_ALL_BOOKINGS_REQUEST,
    });

    const {
        auth: { token },
    } = getState();

    try {
        const { data: report } = await axios.get(
            `https://api.gogym.fit/${S3_REPORTS_BUCKET_NAME}/admin-bookings/year/all-bookings-${year}.xlsx`,
            {
                responseType: 'blob',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        const url = window.URL.createObjectURL(new Blob([report]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
            'download',
            `all-bookings-year-${year}-${moment().format(
                'DD-MM-YYYY-HH-mm'
            )}.xlsx`
        );
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(url);
    } catch (err) {
        if (err instanceof Error) {
            dispatch({
                type: FETCH_REPORT_ALL_BOOKINGS_ERROR,
            });

            throw err;
        }
    }
};

export const fetchAllUsers: AsyncActionCreator<void, void> = () => async (
    dispatch,
    getState
) => {
    dispatch({
        type: FETCH_REPORT_ALL_USERS_REQUEST,
    });

    const {
        auth: { token },
    } = getState();
    try {
        const { data: report } = await axios.get(
            `${REPORTS_API_URL}/app-users`,
            {
                responseType: 'blob',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        const url = window.URL.createObjectURL(new Blob([report]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
            'download',
            `all-users-${moment().format('DD-MM-YYYY-HH-mm')}.csv`
        );
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(url);
    } catch (err) {
        if (err instanceof Error) {
            dispatch({
                type: FETCH_REPORT_ALL_USERS_ERROR,
            });

            throw err;
        }
    }
};

export const fetchAllGyms: AsyncActionCreator<void, any> = () => async (
    dispatch,
    getState
) => {
    dispatch({
        type: FETCH_REPORT_ALL_GYMS_REQUEST,
    });

    const {
        auth: { token },
    } = getState();
    try {
        const { data: report } = await axios.get(`${REPORTS_API_URL}/gyms`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        dispatch({
            type: FETCH_REPORT_ALL_GYMS_SUCCESS,
            payload: report,
        });

        return report;
    } catch (err) {
        if (err instanceof Error) {
            dispatch({
                type: FETCH_REPORT_ALL_GYMS_ERROR,
            });

            throw err;
        }
    }
};

export const fetchAllLegalEntities: AsyncActionCreator<
    void,
    any
> = () => async (dispatch, getState) => {
    dispatch({
        type: FETCH_REPORT_ALL_LEGALENTITIES_REQUEST,
    });

    const {
        auth: { token },
    } = getState();
    try {
        const { data: report } = await axios.get(
            `${REPORTS_API_URL}/legal-entities`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        dispatch({
            type: FETCH_REPORT_ALL_LEGALENTITIES_SUCCESS,
            payload: report,
        });

        return report;
    } catch (err) {
        if (err instanceof Error) {
            dispatch({
                type: FETCH_REPORT_ALL_LEGALENTITIES_ERROR,
            });

            throw err;
        }
    }
};

export const fetchAllCorporate: AsyncActionCreator<void, any> = () => async (
    dispatch,
    getState
) => {
    dispatch({
        type: FETCH_REPORT_ALL_CORPORATE_REQUEST,
    });

    const {
        auth: { token },
    } = getState();
    try {
        const { data: report } = await axios.get(
            `${REPORTS_API_URL}/corporate`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        dispatch({
            type: FETCH_REPORT_ALL_CORPORATE_SUCCESS,
            payload: report,
        });

        return report;
    } catch (err) {
        if (err instanceof Error) {
            dispatch({
                type: FETCH_REPORT_ALL_CORPORATE_ERROR,
            });

            throw err;
        }
    }
};

export const fetchAllOnlineWorkouts: AsyncActionCreator<
    void,
    any
> = () => async (dispatch, getState) => {
    dispatch({
        type: FETCH_REPORT_ALL_ONLINE_WORKOUTS_REQUEST,
    });

    const {
        auth: { token },
    } = getState();
    try {
        const { data: report } = await axios.get(
            `${REPORTS_API_URL}/online-workouts`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        dispatch({
            type: FETCH_REPORT_ALL_ONLINE_WORKOUTS_SUCCESS,
            payload: report,
        });

        return report;
    } catch (err) {
        if (err instanceof Error) {
            dispatch({
                type: FETCH_REPORT_ALL_ONLINE_WORKOUTS_ERROR,
            });

            throw err;
        }
    }
};

export const fetchAccountingReportMonth: AsyncActionCreator<
    { year: string; month: string; type: string },
    any
> = (values) => async (dispatch, getState) => {
    dispatch({
        type: FETCH_REPORT_ACCOUNTING_REQUEST,
    });

    const {
        auth: { token },
    } = getState();

    try {
        const { data: report } = await axios.get(
            `${REPORTS_API_URL}/accounting`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params: {
                    duration: 'MONTH',
                    year: values.year,
                    month: values.month,
                    type: values.type,
                },
            }
        );

        dispatch({
            type: FETCH_REPORT_ACCOUNTING_SUCCESS,
            payload: report,
        });

        return report;
    } catch (err) {
        if (err instanceof Error) {
            dispatch({
                type: FETCH_REPORT_ACCOUNTING_ERROR,
            });

            throw err;
        }
    }
};

export const fetchAccountingReportYear: AsyncActionCreator<
    { year: string; type: string },
    void
> = ({ year, type }) => async (dispatch, getState) => {
    dispatch({
        type: FETCH_REPORT_ACCOUNTING_REQUEST,
    });

    const {
        auth: { token },
    } = getState();

    try {
        const { data: report } = await axios.get(
            `https://api.gogym.fit/${S3_REPORTS_BUCKET_NAME}/admin-${type.toLocaleLowerCase()}s/year/accounting-report-${year}.xlsx`,
            {
                responseType: 'blob',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        const url = window.URL.createObjectURL(new Blob([report]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
            'download',
            `${type.toLocaleLowerCase()}s-accounting-report-year-${year}-${moment().format(
                'DD-MM-YYYY-HH-mm'
            )}.xlsx`
        );
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(url);
    } catch (err) {
        if (err instanceof Error) {
            dispatch({
                type: FETCH_REPORT_ACCOUNTING_ERROR,
            });

            throw err;
        }
    }
};

export const fetchAllMemberships: AsyncActionCreator<void, any> = () => async (
    dispatch,
    getState
) => {
    dispatch({
        type: FETCH_REPORT_ALL_MEMBERSHIPS_REQUEST,
    });

    const {
        auth: { token },
    } = getState();
    try {
        const { data: report } = await axios.get(
            `${REPORTS_API_URL}/memberships`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params: { type: 'ALL' },
            }
        );

        dispatch({
            type: FETCH_REPORT_ALL_MEMBERSHIPS_SUCCESS,
            payload: report,
        });

        return report;
    } catch (err) {
        if (err instanceof Error) {
            dispatch({
                type: FETCH_REPORT_ALL_MEMBERSHIPS_ERROR,
            });

            throw err;
        }
    }
};

export const fetchMonthMemberships: AsyncActionCreator<
    { year: string; month: string },
    any
> = (values) => async (dispatch, getState) => {
    dispatch({
        type: FETCH_REPORT_ALL_MEMBERSHIPS_REQUEST,
    });

    const {
        auth: { token },
    } = getState();

    try {
        const { data: report } = await axios.get(
            `${REPORTS_API_URL}/memberships`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params: {
                    type: 'MONTH',
                    year: values.year,
                    month: values.month,
                },
            }
        );

        dispatch({
            type: FETCH_REPORT_ALL_MEMBERSHIPS_SUCCESS,
            payload: report,
        });

        return report;
    } catch (err) {
        if (err instanceof Error) {
            dispatch({
                type: FETCH_REPORT_ALL_MEMBERSHIPS_ERROR,
            });

            throw err;
        }
    }
};

export const fetchAllUserSubscriptions: AsyncActionCreator<
    void,
    any
> = () => async (dispatch, getState) => {
    dispatch({
        type: FETCH_REPORT_ALL_USER_SUBSCRIPTIONS_REQUEST,
    });

    const {
        auth: { token },
    } = getState();
    try {
        const { data: report } = await axios.get(
            `${REPORTS_API_URL}/subscriptions`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        dispatch({
            type: FETCH_REPORT_ALL_USER_SUBSCRIPTIONS_SUCCESS,
            payload: report,
        });

        return report;
    } catch (err) {
        if (err instanceof Error) {
            dispatch({
                type: FETCH_REPORT_ALL_USER_SUBSCRIPTIONS_ERROR,
            });

            throw err;
        }
    }
};

export const fetchGymafon: AsyncActionCreator<void, void> = () => async (
    dispatch,
    getState
) => {
    dispatch({
        type: FETCH_REPORT_GYMAFON_REQUEST,
    });

    const {
        auth: { token },
    } = getState();
    try {
        const { data: report } = await axios.get(
            `${REPORTS_API_URL}/bookings/gymafon`,
            {
                responseType: 'blob',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        const url = window.URL.createObjectURL(new Blob([report]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
            'download',
            `gymafon-${moment().format('DD-MM-YYYY-HH-mm')}.csv`
        );
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(url);
    } catch (err) {
        if (err instanceof Error) {
            dispatch({
                type: FETCH_REPORT_GYMAFON_ERROR,
            });

            throw err;
        }
    }
};

export const generatePartnerReport: AsyncActionCreator<
    {
        type: string;
        year: number;
        month: number;
    },
    void
> = (params) => async (dispatch, getState) => {
    dispatch({
        type: GENERATE_PARTNER_REPORT_REQUEST,
    });

    const {
        auth: { token },
    } = getState();
    try {
        await axios.get(`${REPORTS_API_URL}/partners/generate`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
            params,
        });

        cogoToast.success('Отчет создан', {
            position: 'top-right',
            hideAfter: 5,
        });

        dispatch({
            type: GENERATE_PARTNER_REPORT_SUCCESS,
        });
    } catch (err) {
        const message =
            (err as AxiosError).response?.data?.message ||
            'Ошибка при создании отчета';

        cogoToast.error(message, {
            position: 'top-right',
            hideAfter: 5,
        });

        dispatch({
            type: GENERATE_PARTNER_REPORT_ERROR,
        });

        throw err;
    }
};

export const confirmPartnerReport: AsyncActionCreator<
    {
        type: string;
        year: number;
        month: number;
    },
    void
> = (params) => async (dispatch, getState) => {
    dispatch({
        type: CONFIRM_PARTNER_REPORT_REQUEST,
    });

    const {
        auth: { token },
    } = getState();

    try {
        await axios.put(
            `${REPORTS_API_URL}/partners/v1`,
            {},
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params: { ...params, option: 'UPDATE' },
            }
        );

        cogoToast.success('Отчет подтвержден', {
            position: 'top-right',
            hideAfter: 5,
        });

        dispatch({
            type: CONFIRM_PARTNER_REPORT_SUCCESS,
        });
    } catch (err) {
        cogoToast.error('Ошибка при подтверждении отчета', {
            position: 'top-right',
            hideAfter: 5,
        });

        dispatch({
            type: CONFIRM_PARTNER_REPORT_ERROR,
        });

        throw err;
    }
};

export const deletePartnerReport: AsyncActionCreator<
    {
        type: string;
        year: number;
        month: number;
    },
    void
> = (params) => async (dispatch, getState) => {
    dispatch({
        type: DELETE_PARTNER_REPORT_REQUEST,
    });

    const {
        auth: { token },
    } = getState();

    try {
        await axios.put(
            `${REPORTS_API_URL}/partners/v1`,
            {},
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params: { ...params, option: 'DELETE' },
            }
        );

        cogoToast.success('Отчет удален', {
            position: 'top-right',
            hideAfter: 5,
        });

        dispatch({
            type: DELETE_PARTNER_REPORT_SUCCESS,
        });
    } catch (err) {
        cogoToast.error('Ошибка при удалении отчета', {
            position: 'top-right',
            hideAfter: 5,
        });

        dispatch({
            type: DELETE_PARTNER_REPORT_ERROR,
        });

        throw err;
    }
};
